<template>
    <header class="header" :style="{'background-color': backgroundColor,'position': fixed?'fixed':'relative'}">
        <h1 class="logo">
            <img v-if="logo" src="../assets/svg/logo-white.svg" />
            <img v-else src="../assets/svg/into-ai.svg" />
            <span :style="{color:logoColor}" class="text">多源网络</span>
        </h1>

        <nav>
            <ul>
                <li :class="{active: active == '/'}">
                    <router-link to="/" :style="{color: active == '/'?activeTextColor:textColor}">首页</router-link>
                </li>
                <li :class="{active: active == 'product'}">
                    <router-link to="/product" :style="{color: active == 'product'?activeTextColor:textColor}">技术能力</router-link>
                </li>
                <li :class="{active: active == 'market'}">
                    <router-link to="/market" :style="{color: active == 'market'?activeTextColor:textColor}">营销策划</router-link>
                </li>

                <li :class="{active: active == 'news'}">
                    <router-link to="/news" :style="{color: active == 'news'?activeTextColor:textColor}">最新动态</router-link>
                </li>
                <li :class="{active: active == 'contect'}">
                    <router-link to="/contect" :style="{color: active == 'contect'?activeTextColor:textColor}">合作联系</router-link>
                </li>
            </ul>
        </nav>
    </header>
</template>

<style lang="scss" scoped>

header {
    padding: 0 80px;
    height: 80px;
    width: 100%;
    box-sizing: border-box;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    z-index: 9;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    flex: none;

    .logo {
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        min-width: 100px;
        max-width: 146px;
        cursor: pointer;

        img {
            height: 32px;
            width: 32px;
        }

        span.text {
            height: 32px;
            display: inline-flex;
            position: relative;
            font-size: 18px;
            line-height: 32px;
            color: #fff;
            padding: 0 10px;
            font-weight: bold;
        }
    }


    nav {
        ul {
            padding-left: 50px;
            overflow: hidden;

            li {
                float: left;
                a {
                    display: block;
                    line-height: 80px;
                    padding: 0 30px;
                    font-size: 18px;
                    text-decoration: none;
                }
                &.active a {
                    font-weight: 600;
                }
            }
        }
    }
}
</style>


<script>
export default {
    name: "WebHeader",
    props: {
        active: String,
        backgroundColor: {
            default: 'none',
            type: String
        },
        logoColor: {
            default: 'rgba(255, 255, 255, 1)',
            type: String
        },
        textColor: {
            default: 'rgba(255, 255, 255, .75)',
            type: String
        },
        activeTextColor: {
            default: 'rgba(255, 255, 255, 1)',
            type: String
        },
        logo: {
            default: true,
            type: Boolean
        },
        fixed: {
            default: false,
            type: Boolean
        },
    }
};
</script>