<template>
    <section class="app">
        <WebHeader active="market" />
        <section class="banner" />

        <div class="content">
            <h2>板块策划中</h2>
            <p class="desc"></p>
        </div>

        <WebFooter></WebFooter>
    </section>
</template>


<style lang="scss" scoped>
.app {
    background: #f0f1f3;
    .banner {
        margin-top: -80px;
        width: 100%;
        background: #4dc56e;
        height: 300px;
    }

    .content {
        width: 1200px;
        min-height: 280px;
        margin: -150px auto 100px auto;
        position: relative;
        background: #fff;
        border-radius: 30px;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
        padding: 50px;

        h2{
            font-size: 40px;
            color: #333;
        }
        desc{
            font-size: 16px;
            color: #666;
        }
    }
}
</style>


<script>
import WebHeader from "../../components/web-header.vue";
import WebFooter from "../../components/web-footer.vue";
export default {
    components: { WebHeader,WebFooter }
};
</script>