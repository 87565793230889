<template>
    <footer class="footer">
        <div class="warp">
            <div class="upper">
                <div class="left">
                    <div class="group">
                        <div class="item">
                            <div class="info">
                                <div class="title">
                                    <phone-telephone theme="outline" size="25" fill="#666" :strokeWidth="3"/>
                                    <span class="text">联系电话</span>
                                </div>
                                <div class="desc">132 0586 0904</div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="info">
                                <div class="title">
                                    <work-time theme="outline" size="23" fill="#666" :strokeWidth="3"/>
                                    <span class="text">服务时间</span>
                                </div>
                                <div class="desc">工作日 09:30-19:00</div>
                            </div>
                        </div>
                    </div>

                    <div class="group">
                        <div class="item">
                            <div class="info">
                                <div class="title">
                                    <mail theme="outline" size="23" fill="#666" :strokeWidth="3"/>
                                    <span class="text">联系邮箱</span>
                                </div>
                                <div class="desc">sinda@srcker.com</div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="info">
                                <div class="title">
                                    <wechat theme="outline" size="23" fill="#666" :strokeWidth="3"/>
                                    <span class="text">联系微信</span>
                                </div>
                                <div class="desc">srcker@多源网络</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="right">
                    <img src="../assets/images/qrcode.jpg" />
                </div>
            </div>
            <div class="lower"><a href="http://www.beian.miit.gov.cn">鲁ICP备18029286号-1</a></div>
        </div>
    </footer>
</template>

<style lang="scss" scoped>
footer {
    background: #28292b;
    color: #6e6f73;
    width: 100%;
    .warp {
        width: 1080px;
        margin: 0 auto;
        .upper {
            border-bottom: 1px solid rgba(255, 255, 255, 0.05);
            padding-top: 50px;
            padding-bottom: 50px;
            display: flex;
            align-items: center;
            .left {
                flex: 1;
                .group {
                    display: flex;
                    &:first-child {
                        margin-bottom: 40px;
                    }
                    .item {
                        flex: 1;
                        .info {
                            .title {
                                font-size: 14px;
                                color: #6e6f73;
                                font-size: 14px;
                                display: flex;
                                span.text{
                                    color: #666;
                                    padding-left: 5px;
                                }
                            }

                            .desc {
                                padding-left: 30px;
                                padding-top: 5px;
                                font-size: 16px;
                                color: #979aa2;
                            }
                        }
                    }
                }
            }
            .right {
                padding-left: 20px;
                img{
                    width: 150px;
                    border-radius: 5px;
                }
            }
        }
        .lower {
            padding: 15px 0;
            font-size: 14px;
            text-align: right;
            a{
                color: #6e6f73;
                text-decoration: none;
            }
        }
    }
}
</style>


<script>
import {PhoneTelephone,Mail,Wechat,Time} from '@icon-park/vue-next'
export default {
    name: "WebFooter",
    components: {
        PhoneTelephone,Mail,Wechat,WorkTime:Time
    },

};
</script>